import React from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import "./Arrow.css";
export default function Arrow(props) {
    if (props.count < 2) {
        return (
            <div>
                <MdKeyboardArrowDown 
                    style={{color: "#0637A6"}} 
                    onClick={props.incrementCount} 
                    size={30}
                />
            </div>
        ) 
    } else {
        return (
            <div>
                <MdKeyboardArrowUp 
                    style={{color: "#0637A6"}} 
                    onClick={props.incrementCount} 
                    size={30}/>
            </div>
        )
    }
    
}
