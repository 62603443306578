import React from 'react'
import "./App.css";
import "./Projects.css";
import PortablePoker from "./images/portablepoker.png";
import Bittle from "./images/bittle.jpeg";
import SyntaxTree from "./images/syntaxtree.png";
import HKIME from "./images/hkime.png";
import Labs from "./images/15213labs.png";
import MovieAssist from "./images/movieassist.gif";
import DrumAR from "./images/drumar.gif";
import RaspPi from "./images/rasppi.png";
export default function Projects() {
    return (
        <div className="projects">
            <h1>Projects</h1>
            <div className="projects__list">
                <div className="projects__container">
                    <img className="projects__container__img" src={PortablePoker} height={240}></img>        
                    <h1 className="projects__container__text">Portable Poker</h1> 
                    <p className="projects__container__description">
                        A real-time multiplayer poker chip simulator, built using React, Node, Express and SocketIO.
                    </p> 
                </div>
                <div className="projects__container">
                    <img className="projects__container__img" src={Bittle} height={240}></img>        
                    <h1 className="projects__container__text">Bittle</h1> 
                    <p className="projects__container__description">
                        Implemented human following and smooth gait transitions for arduino based robot dog.
                    </p> 
                </div>
                <div className="projects__container">
                    <img className="projects__container__img" src={SyntaxTree} height={240}></img>        
                    <h1 className="projects__container__text">Question Answering</h1> 
                    <p className="projects__container__description">
                        A system that generates questions from Wikipedia articles and provide answers to the questions.
                    </p> 
                </div>
                <div className="projects__container">
                    <img className="projects__container__img" src={HKIME} height={240}></img>        
                    <h1 className="projects__container__text">Cantonese Input Method</h1> 
                    <p className="projects__container__description">
                        Intelligent input method for Cantonese Jyutping. Features include treatment of fuzzy (ambiguous) Jyutping, word prediction, and typo correction.                    
                    </p> 
                </div>
                <div className="projects__container">
                    <img className="projects__container__img" src={Labs} height={240}></img>        
                    <h1 className="projects__container__text">Computer Systems Labs</h1> 
                    <p className="projects__container__description">
                        Implemented segregrated list malloc, linux shell with job control and I/O, concurrent proxy with cache, and a simulated memory cache. 
                    </p> 
                </div>
                <div className="projects__container">
                    <img className="projects__container__img" src={MovieAssist} height={240}></img>        
                    <h1 className="projects__container__text">MovieAssist</h1> 
                    <p className="projects__container__description">
                        A system that recognizes a movie from its poster and a tkinter GUI that allows interaction with a movie recommendation system.
                    </p> 
                </div>
                <div className="projects__container">
                    <img className="projects__container__img" src={DrumAR} height={240}></img>        
                    <h1 className="projects__container__text">Drum AR</h1> 
                    <p className="projects__container__description">
                        openCV based drumset that was built for Hack112. We won second-place for the project.
                    </p> 
                </div>
                <div className="projects__container">
                    <img className="projects__container__img" src={RaspPi} height={240}></img>        
                    <h1 className="projects__container__text">RaspberryPi<br/>Alexa</h1> 
                    <p className="projects__container__description">
                        A fully functional Alexa running on a Raspberry Pi with a customizable wake word.
                    </p> 
                </div>
            </div>
        </div>
        
    )
}
